import RecaptchaForm from '../components/forms/RecaptchaForm';

export default class Contact {
  constructor() {
    // Recaptcha submit handler for each form
    const contactForm = new RecaptchaForm('#form-contact');
    window.submitRecaptchaFormContact = () => {
      contactForm.submitCallback();
    };
  }
}
