import $ from 'jquery';

export default function () {
  // Home slider
  $('.js-realisations').slick({
    centerMode: false,
    slidesToShow: 3,
    // centerPadding: '60px',
    variableWidth: true,
    infinite: true,
    dots: false,
    arrows: false,
    focusOnSelect: true,
  });

  // Realisation page slider
  $('.js-realisation-spotlight').slick({
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    // nextArrow: `<div class="next">
    //   <i class="icon icon-arrow-right"></i>
    // </div>`,
    // prevArrow: `<div class="prev">
    //   <i class="icon icon-arrow-left"></i>
    // </div>`,
    nextArrow: $('.icon-arrow-right'),
    prevArrow: $('.icon-arrow-left'),
  });

  $('.image').modaal({
    type: 'image',
  });
}
