import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
const Isotope = require('isotope-layout');
require('isotope-packery');
// eslint-disable-next-line no-unused-vars
const Blazy = require('blazy');

export default function () {
  // eslint-disable-next-line no-unused-vars
  if ($('.realisation-filter').length) {
    // const $items = $('.realisation-filter .grid__item');

    $('.realisation-filter').imagesLoaded(({ background: '.realisation__item' }), () => {
      const $realisations = new Isotope('.realisation-filter', {
        layoutMode: 'packery',
        itemSelector: '.grid__item',
        percentPosition: true,
      });
      $('.filter-menu').on('click', 'a', function showItems() {
        const categoryId = $(this).attr('data-catId');
        $('.filter-menu').data('clicked', true);

        $realisations.arrange({
          filter() {
            const blockCategoryId = $(this).attr('data-cat');
            if (categoryId === '*') {
              return true;
            }
            return blockCategoryId === categoryId;
          },
        });
      });
      $realisations.on('arrangeComplete', (filteredItems) => {
        if (filteredItems.length === 0) {
          $('.no-results').show();
          $('.load-more').hide();
        } else {
          $('.no-results').hide();
          $('.load-more').show();
        }
      });
    });
  }


  if ($('.realisation-grid').length) {
    $('.realisation-grid').imagesLoaded(() => {
      // eslint-disable-next-line no-unused-vars
      const $realisations = new Isotope('.realisation-grid', {
        layoutMode: 'packery',
        itemSelector: '.grid__item',
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  const bLazy = new Blazy();
}
