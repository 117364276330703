import $ from 'jquery';

export default function () {
  $('.js-services-mobile__trigger').click((e) => {
    e.preventDefault();
    $('.js-services-mobile').slideToggle('fast');
    // $('.js-services__trigger').toggleClass('open');
  });

  // Hide button when click outside div + disable button
  $(document).mouseup((e) => {
    const container = $('.js-services');
    const button = $('.js-services__trigger');
    if ((!container.is(e.target) && container.has(e.target).length === 0)
    && (!button.is(e.target) && button.has(e.target).length === 0)) {
      container.slideUp('fast');
      button.removeClass('open');
    }
  });
}
