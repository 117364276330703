import $ from 'jquery';
import SmoothScroll from 'smooth-scroll';
import { matchMobile } from '../../constants/defaults';

export default function () {
  const $nav = $('.main-nav__wrap');

  $('.main-nav__trigger').modaal({
    fullscreen: true,
  });

  $('.js-services__trigger').click((e) => {
    e.preventDefault();
    $('.js-services').slideToggle('fast');
    $('.js-services__trigger').toggleClass('open');
  });


  matchMobile.addListener((mediaQuery) => {
    if (mediaQuery.matches) {
      $nav.css('display', 'block');
    } else {
      $nav.css('display', 'none');
    }
  });

  // eslint-disable-next-line no-unused-vars
  const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 400,
    easing: 'easeInCubic',
  });
}
